<template>
  <div class="cash">
    <div class="cashTitle paddingTop">
      <div class="bottom">
        <div class="cashMoney"><span>提现金额</span></div>
        <div class="cashNum">
          ￥<input v-model="mount"
                 type="text" />
        </div>
        <div class="showTxt">
          <span>当前零钱余额{{allMount}}元，<span class="blue"
                  @click="mount = allMount">全部提现</span></span>
        </div>
        <!-- <div class="btn" v-if="isCashing">
          <div class="cantCash">提现中</div>
        </div>
        <div class="btn" @click="cashMoney" v-else>
          <div :class="(allMount - 0 > 0) ?'quitBtn' : 'cantCash'">提现</div>
        </div> -->
      </div>
    </div>
    <div class="cashTitle">
      <div class="bottomTo">
        <div class="cashMoneyTo"><span>提现到</span></div>
        <div class="bankcard">
          <div class="cardMsg">
            <div class="peaceBand">
              <div class="card"><span>{{bankName}}</span></div>
              <!-- <div class="recommend"><span>推荐</span></div> -->
            </div>
            <div class="twoHours"><span>2小时内到账</span></div>
          </div>
          <div class="cashIcon">
            <md-icon name="checked"
                     class="rightIcon"
                     size="lg"></md-icon>
          </div>
        </div>
        <div class="showMoreType"
             @click="changeCompany">
          <span>展开更多提现方式</span>
          <md-icon name="arrow-down"
                   class="showIcon"
                   size="lg"></md-icon>

        </div>
        <md-selector v-model="isSelectorShow"
                     :data="insuranceCompany"
                     :default-value="bankno"
                     max-height="320px"
                     title="请选择银行卡"
                     @choose="onSelectorChoose"></md-selector>
      </div>
    </div>
    <div class="cashTitle">
      <div class="bottom rules">
        <div class="cashRules">
          <span class="red">
            <md-icon name="info-solid"
                     class="rightIcon"
                     size="lg"></md-icon>提现规则：
          </span><br />
          <span class="grey">1、提现金额不能小于<span class="red">最低提现金额，</span>不得高于最大提现金额，首次提现不限制金额</span><br />
          <span class="grey">2、每月前两次提现免手续费，<span class="red">超过则需扣手续费。</span></span><br />
          <span class="grey">3、提现仅是申请提现，后台会有审核功能，并非立即到账。</span><br />
          <span class="grey">4、提现申请后，立即减少可提现金额，避免重复提取。提现明细状态为“提现中”。</span><br />
          <span class="grey">5、提现支付后，提现明细状态为“提现成功”。</span>
        </div>
      </div>
    </div>
    <div class="cashTitle">
      <div class="btn"
           v-if="isCashing">
        <div class="cantCash">提现中</div>
      </div>
      <div class="btn"
           @click="cashMoney"
           v-else>
        <div :class="(allMount - 0 > 0) ?'quitBtn' : 'cantCash'">提现</div>
      </div>
    </div>
    <!-- <div v-show="isShowRefuseMsg" class="showZyImg">
      <div class="zyImg">
        <div>
          <img src="@/assets/nh/zyzz.png" class="manageImg" alt="">
        </div>
        <div class="zzMsg">
          <div class="itemTitle">
            <span>拒绝详情</span>
            <div class="code">
              <span>{{refuseMsg}}</span>
            </div>
          </div>
        </div>
        <div class="closeImg">
          <img src="@/assets/nh/sctp.png" @click="isShowRefuseMsg = false" alt="">
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { upMount, getTXRecord, selectEmpYHKInfo } from '@/api/basic/index'
import { Toast } from 'mand-mobile'
import loadMore from '@/mixins/loadmore'
export default {
  mixins: [loadMore],
  data () {
    return {
      mount: '',
      allMount: '',
      showFullScreen: false,
      loading: false,
      isNull: false,
      noCashList: [],
      cashLists: [],
      pageNo: 1,
      size: 10,
      total: 0,
      totalPage: 2,
      isShowRefuseMsg: false,
      refuseMsg: '',
      isCashing: false,
      bankName: '',
      bankno: '',
      insuranceCompany: [],
      isSelectorShow: false
    }
  },
  created () {
    this.choseSupplyList()
  },
  mounted () {
    this.getbfData()
  },
  methods: {
    // 银行卡列表
    choseSupplyList () {
      selectEmpYHKInfo().then((res) => {
        let arr = res.data.data
        this.insuranceCompany = []
        for (var i = 0; i < arr.length; i++) {
          let arrobject = {
            value: arr[i].bankno,
            text: arr[i].txInfo
          }
          this.insuranceCompany.push(arrobject)
          this.bankno = arr[0].bankno
          this.bankName = arr[0].txInfo
        }
      })
    },
    changeCompany () {
      this.isSelectorShow = true
    },
    onSelectorChoose ({ value, text }) {
      this.bankno = value
      this.bankName = text
      // this.baseid = value
      // this.pageNo = 1
      // this.getData()
    },

    cashMoney () {
      if (this.allMount - 0 <= 0) {
        return
      }
      if (this.allMount - 0 - this.mount < 0) {
        Toast.info(`您的可提现金额只有${this.allMount}元！`)
        return
      }
      if ((this.mount - 0) >= 100 && (this.mount - 0) <= 4999) {
        this.isCashing = true
        upMount({ mount: this.mount, bankno: this.bankno }).then(res => {
          // Toast.succeed('提现申请已提交，请等待审核')
        }).finally(() => {
          this.isCashing = false
          this.$router.push(`/cashDetails`)
        })
      } else {
        Toast.info('提现金额最低100元，最高4999元！')
      }
    },
    showMore () {
      this.showFullScreen = true
      this.getData()
    },
    // 部分
    getbfData () {
      let data = {
        page: 1,
        size: 10
      }
      getTXRecord(data).then(res => {
        this.noCashList = res.data.data.rows
        this.allMount = res.data.data.blankmessage.balance
        // this.bankName = res.data.data.blankmessage.bankname
        console.log(res)
      })
    },
    // 显示拒绝理由
    showRefuseMsg (val) {
      console.log(val)
      if (val.qrstatus == '2') {
        this.refuseMsg = val.remark
        this.isShowRefuseMsg = true
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.cash {
  background-color: #F6F6F6;
  height: 100%;

  .paddingTop {
    padding-top: 130px !important;
  }

  .cashTitle {
    padding-top: 30px;
    margin: 0px 40px;

    // padding 0px 80px
    .top {
      padding: 40px 80px;
      background-color: #F7F7F7;

      .toCard {
        display: flex;
        justify-content: space-between;

        span {
          font-size: 35px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }
      }

      .arrivalAccount {
        text-align: center;
        padding-top: 20px;

        span {
          font-size: 35px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(152, 152, 152, 1);
        }
      }
    }

    .bottom {
      padding: 40px 75px;
      background-color: #FFFFFF;

      .cashMoney {
        span {
          font-size: 46px;
          font-family: SourceHanSansCN;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
        }
      }

      .cashNum {
        padding: 40px 0px;
        font-size: 97px;
        font-family: SourceHanSansCN;
        font-weight: 800;
        color: rgba(51, 51, 51, 1);
        border-bottom: 1px solid #F6F6F6;

        input {
          overflow: hidden;
          font-size: 90px;
          border: none;
          text-align: left;
          outline: none;
          width: 80%;
        }
      }

      .showTxt {
        padding-top: 40px;

        span {
          font-size: 32px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          color: #B3B3B3;
        }

        .blue {
          color: color-primary !important;
        }
      }
    }

    .rules {
      background-color: #F5F3F3 !important;

      .cashRules {
        .red {
          font-size: 29px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          color: rgba(241, 68, 70, 1);
          line-height: 46px;
          letter-spacing: 29;
        }

        .grey {
          font-size: 27px;
          font-family: SourceHanSansCN;
          font-weight: 400;
          color: rgba(178, 178, 178, 1);
          line-height: 46px;
          letter-spacing: 29;
        }
      }
    }
  }

  .bottomTo {
    // margin 40px 80px
    background-color: #FFFFFF;

    .cashMoneyTo {
      padding: 40px 75px;
      border-bottom: 1px solid #F6F6F6;

      span {
        font-size: 46px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
      }
    }

    .bankcard {
      padding: 30px 75px;
      border-bottom: 1px solid #F6F6F6;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cardMsg {
        .peaceBand {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .card {
            span {
              font-size: 39px;
              font-family: SourceHanSansCN;
              font-weight: 500;
              color: rgba(241, 68, 70, 1);
            }
          }

          .recommend {
            padding-left: 23px;

            span {
              background-color: #FFEACF;
              padding-left: 10px;
              padding-right: 10px;
              font-size: 31px;
              font-family: SourceHanSansCN;
              font-weight: 400;
              color: rgba(255, 141, 53, 1);
            }
          }
        }

        .twoHours {
          padding-top: 22px;

          span {
            font-size: 30px;
            font-family: SourceHanSansCN;
            font-weight: 500;
            color: rgba(179, 179, 179, 1);
          }
        }
      }
    }

    .showMoreType {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 60px;

      span {
        font-size: 36px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: rgba(179, 179, 179, 1);
      }

      .showIcon {
        font-size: 36px;
        color: rgba(179, 179, 179, 1);
        padding-left: 30px;
      }
    }
  }
}

.header {
  width: 100%;
  height: 130px;
  border-bottom: 1px solid #dbdddc;
  text-align: center;
  // line-height: 50px;
  background: #ffffff;
  position: fixed;
  // top: 100px;
  // left: 0;
  z-index: 9;

  span {
    margin-top: 130px;
    line-height: 130px;
    font-size: 40px;
    font-weight: 600;
  }
}

.imgList {
  padding-top: 150px;
  // display flex
  // flex-wrap wrap
  // padding 30px 50px
  border-bottom: 1px solid #F6F6F6;

  .cashitem {
    padding: 30px 50px;
    // width:33%;
    // text-align:center;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .itemNum {
      font-size: 45px;
      color: rgba(56, 56, 56, 1);
      font-family: PingFang SC;
      font-weight: 500;
    }

    .itemStatus {
      padding-top: 10px;
      font-size: 35px;
      color: rgba(152, 152, 152, 1);
    }

    .itemMoney {
      font-size: 50px;
      color: #BA1C21;
    }
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}

.showZyImg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  padding: 250px 150px;
  background: rgba(0, 0, 0, 0.7);

  .zyImg {
    position: relative;

    .zzMsg {
      position: absolute;
      top: 30%;
      width: 100%;

      .itemTitle {
        text-align: center;
        padding-bottom: 50px;

        span {
          font-size: 50px;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(56, 56, 56, 1);
        }

        .code {
          // margin-top: 20px;
          // margin-top: 120px;
          text-align: left;
          margin: 120px 50px 0px;

          span {
            font-weight: 500;
            font-size: 35px !important;
          }
        }
      }
    }

    .closeImg {
      text-align: center;
      padding-top: 30px;

      img {
        width: 100px;
        height: auto;
      }
    }
  }
}

.solid {
  border-bottom: 1px solid #F6F6F6;
  padding-bottom: 40px;
}

.cashIcon {
  // text-align center
  // background-color #f14446
  // margin-right 10px
  // border-radius 50%
  // width 43px
  // height 43px
  float: left;

  // span {
  // color #fff !important
  // padding-left 13px
  // font-size 35px !important
  // }
  .rightIcon {
    // color #fff !important
    color: rgba(241, 68, 70, 1);
    font-size: 40px !important;
  }
}

.btn {
  text-align: center;
  padding-bottom: 40px;

  // padding-top 200px
  .quitBtn {
    padding: 30px 30px;
    // margin: 0px auto;
    width: 100%;
    background-color: color-primary;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    border-radius: 100px;
  }

  .cantCash {
    padding: 30px 30px;
    background-color: #F2F2F2;
    color: #BFBFBF !important;
    width: 100%;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    border-radius: 100px;
  }
}
</style>
